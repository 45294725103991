<template>
        <el-input
        v-model="query"
        placeholder="空格隔开筛选项"
        clearable
        @input="searchInputFn($event)"
        style="width:240px;margin-right:10px;"
        ></el-input>
</template>

<script>
export default {
  methods: {
    // 向父组件传值，子组件应该并不能直接去修改父组件的值
    SearchInputChild(){
          this.$emit('SearchInput',this.tableSearchListCopy);
    },
    searchInputFn(){
      var that = this;
      clearTimeout(that.searchTimer);
      // 延时0.5s后检索
      that.searchTimer = setTimeout(function () { 
        console.log("在这里停顿");
        that.searchTable()
      },500)
    },
    // 检索函数
    searchTable(){
      // console.log('nico')
      this.tableSearchListCopy = this.search(this.query)
      this.SearchInputChild();
    },
    // 检索匹配逻辑
    search(query) {
      if (query == "")
      {
        console.log("233");
        return this.$parent.tableData;
      }
      else
      {
        // console.log("25252");
        let tableData = this.$parent.tableData.filter((item) => {
        var array = query.split(' ');
            if (array.length== 1 && array[0] == '')
            {
            return item;
            }
            else
            {
            for (let l in array){if (Object.values(item).join().toLowerCase().includes(array[l].toLowerCase())){}else{return;};};
            return item;
            }
        });
        // this.pageInfoTotal = tableData.length;
        return tableData
      }

    },
  },
 data() {
    return {
        query:'',
        searchTimer:null,
        tableSearchListCopy:[],
        pageInfoTotal:0
    };
  },
}
</script>
